<template>
  <!-- Навігаційне меню -->

  <div class="navbar-nav menuScroll" >

    <!-- Пошук по меню -->
    <searchBox v-if="this.fullMenu === true" @searchB="searchB" class="searchMenu"/>

      <template v-for="(option, i) in options">
        <!-- Перевірка на відображення меню та наявність підопцій -->
        <template v-if="isShowItem(option) && option.children">
          <li class="nav-item" :key="i">
              <a 
                class="nav-link menu-link collapsed" 
                :href="option.children ? '#'+option.name : undefined" 
                data-bs-toggle="collapse" 
                role="button" 
                aria-expanded="false" 
                aria-controls="sidebarDashboards" 
              >
                <i :class="option.icon"></i> <span>{{option.meta.title()}}</span>
              </a>
              
              <!-- Випадаюче підменю -->
              <div class="menu-dropdown collapse" :id="option.children ? option.name : undefined">
                <!-- Перебір підменю -->
                <template v-for="(suboption, j) in option.children" :key="j">
                  <ul class="nav nav-sm flex-column" v-if="isShowItem(suboption)" style="overflow: hidden">
                    <li class="nav-item"  >
                      <router-link :to="'/'+suboption.to" class="nav-link menu-link" @click="openMenu(suboption.to, null, null)">
                        {{suboption.meta.title ? suboption.meta.title() : ''}}
                      </router-link>
                    </li>
                  </ul>
                </template>
              </div>
          </li>
        </template>

        <!-- Перевірка на відображення опції та відсутність підопцій -->
        <template v-if="isShowItem(option) && !option.children">
          <li class="nav-item" :key="i">
            <router-link class="nav-link menu-link" :to="'/' + option.to" @click="openMenu(option.to, j, null)">
              <i :class="option.icon"></i>
              <span>{{option.meta.title()}}</span>
              
              <!-- Мітка нового розділу -->
              <span class="badge badge-pill bg-danger" v-if="option.dateNew && (option.dateNew > currentDate())">New</span>
            </router-link>
          </li>
        </template>
      </template>
  </div>
</template>

<script>
import router from "@/router"; // Імпорт роутера
import { storeS } from "@/store.js"; //Глобальное хранилище данных
import searchBox from '../../globaltable/searchbox.vue'; // компонент боксу пошуку

export default {
  data(){
    return {
      // Об'єкт для відстеження активного меню
      menu: {
        menu: null,
        submenu: null,
        subsubmenu: null,
      },
      activeMenu: '', // Активне меню
      searchMenu: '' // поле пошуку
    }
  },
  components: { searchBox },
  methods: {
    openMenu(i, j, k){
      // Метод відкриття меню

      let cleanedPath = this.activeMenu.substring(1);
      if(i == cleanedPath) { 
        this.$emit("reloadRoute");
      }
    },
    currentDate(){
      // Отримання поточної дати

      var date = new Date();
      var dateStr =
      date.getFullYear() + "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
      ("00" + (date.getDate() + 1)).slice(-2)
      return dateStr
    },
    isShowItem(option) {
      // Метод перевірки відображення пункту меню та пошук по меню
      
      const title = option.meta.title ? option.meta.title().toLowerCase() : '';
      const search = this.searchMenu.toLowerCase();

      // Перевірка на 1-му рівні
      const isOnFirstLevel = (
        title !== '' &&
        title.includes(search) &&
        (this.perms[666] === true ||
          this.perms[option.meta.perms] === true ||
          this.perms[option.meta.perms] === null)
      );

      // Перевірка на 2-му рівні
      const hasMatchingChild = option.children && Object.values(option.children).some(child => {
        const childTitle = child.meta.title ? child.meta.title().toLowerCase() : '';
        return childTitle !== '' && childTitle.includes(search);
      });

      if(isOnFirstLevel == true && hasMatchingChild == true) {
        return isOnFirstLevel || hasMatchingChild
      } else {
        return isOnFirstLevel
      }
      // return option.meta.title && option.meta.title() != '' && option.meta.title() != null && (this.perms[2000] == true || this.perms[option.meta.perms] == true || this.perms[option.meta.perms] == null);
    },
    onRoutechange(ele) {
      // Метод для відстеження зміни маршруту

      this.initActiveMenu(ele);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
      }
    },
    initActiveMenu(ele) {
      // Метод ініціалізації активного меню

      this.activeMenu = ele
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document.querySelector("#navbar-nav").querySelector('[href="'+ ele +'');
          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                  parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                const grandparent = parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.parentElement.closest(".collapse");
                if(grandparent && grandparent && grandparent.previousElementSibling){
                  grandparent.previousElementSibling.classList.add("active");
                  grandparent.classList.add("show");
                }
              }
            }
          }
        }
      }, 0);
    },
    toggleMenu() {
      // Метод для зміни стану меню
      
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    searchB(e){
      // пошук
      this.searchMenu = e
    }
  },
  watch: {
    $route(to, from) {
      //Ця штука дивиться в якій директорії ми знаходимося та відкриває відповідний пункт меню
      var url = to.path;
      this.onRoutechange(url);
    },
  },
  mounted(){
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");

      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.style.display = "none";
          });
        });
      });
    }
  },
  computed: {
    options() {
      // Отримання опцій меню з глобального сховища

      // Отримання ієрархії меню в потрібному форматі
      let options = {}
      const curMenu = storeS.menu

      // Функція для рекурсивного отримання структури меню
      let getOptionsNode = (localNode) => {
        if (localNode.children) {
          let children = {};
          for (let key in localNode.children) {
            let child = localNode.children[key];
            children[child.name] = getOptionsNode(child);
            if(localNode.name == "Загальна інформація") {
            }
          }
          localNode.children = children;
        }
        return localNode;
      }
      
      for(let key in curMenu) {
        let currentNode = curMenu[key];
        let name = currentNode.name;
        options[name] = getOptionsNode(currentNode);
      }

      return options;
    },
    perms(){
      return storeS.perms
    },
    fullMenu() {
      return storeS.fullMenu;
    }
  }
}
</script>

<style scoped>
a.router-link-active, a.router-link-active>a {
  background: #2196f3;
  border-radius: 15px;
  color: white!important;
  /* margin: 0px 5px; */
  /* border-top-left-radius: 15px;
  border-bottom-left-radius: 15px; */
}

.menuScroll {
  height: calc(100% - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.menuScroll::-webkit-scrollbar {
  width: 5px;
  background-color: #484848;
}

.menuScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #302626af;
}


</style>

<style>
.menuScroll input.form-control.search {
  background: none;
  color: white;
  border: none;
}
/* .menuScroll input.form-control.search:hover {
  border-bottom: 1px solid #bdc1c43d;
}
.menuScroll input.form-control.search:focus {
  border-bottom: 1px solid #bdc1c43d;
} */
.search-box.searchMenu {
  margin: 9px 5%;
  margin-bottom: 17px;
  background: #495057;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgb(56 65 74);
  margin: 17px 7%;
  width: 90%!important;
}
</style>