<template>
    <b-card>
        <div class="d-flex">
            <h6 class="card-title fw-semibold mb-0 flex-grow-1 copy" @click="showblock = !showblock">{{ $t('clients') }}</h6>
            <div class="flex-shrink-0">
                <button type="button" 
                :class="`btn btn-soft-${shownum == true ? 'success' : 'info'} btn-sm`"  
                @click="shownum == true ? this.editnum() : shownum = true"><i class="ri-add-box-fill me-1 align-bottom"></i>{{ shownum == true ? $t('save') : $t('Edit') }}</button>
            </div>
        </div>
        <ul class="list-unstyled vstack gap-3 mb-0 mt-2" v-if="showblock">
            <!-- {{this.form.users_phones}} -->
            <li v-for="(item, index) in form.userPhones" :key="index" class="click">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                        <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-info text-black">#{{index+1}}</div></div>
                    </div>
                    <div class="flex-grow-1 ms-2" @click="open(item)">
                        <h6 class="mb-1">
                            <b>
                                {{item.number ? item.number : item }}  
                                {{
                                    item.companyId && 
                                    item.companyId != 'none' && 
                                    (
                                        this.showComp === true || this.showCompManually === true
                                    ) ? " - " + item.companyId : "" 
                                }}
                            </b>
                        </h6>
                        <span 
                            v-if="item.appealsStat?.count" 
                            class="fs-11 text-muted"
                        >
                            {{ $t('NumberofAppeals') }}: {{ item.appealsStat?.count ? item.appealsStat?.count : "0"  }}, 
                            {{ $t('theLast') }}: {{ item.appealsStat?.date ? item.appealsStat?.date : "-" }}
                        </span>
                    </div>
                        <i class='bx bxs-user-account' @click="copy(item.companyId)" v-if="item.companyId && item.companyId != 'none'" style="font-size: 20px; color: gray; margin-right: 10px;"></i>
                        <i class='bx bxs-phone' @click="copy(item.number)" v-if="item.number" style="font-size: 20px; color: gray;"></i>
                        <i class='bx bxs-phone' @click="copy(item)" v-else style="font-size: 20px; color: gray;"></i>
                        
                        <div class="dropdown" style="margin-left: 5px;">
                            <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i class="bx bx-dots-vertical-rounded align-bottom"></i></button>
                            <div class="dropdown-menu dropdown-menu-end"  data-popper-placement="bottom-end" style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(0px, 36px, 0px);">

                                <!-- 
                                    Повторне звернення
                                -->
                                <button
                                    @click="clientPhone = item.number;showAdd_appeal.show = true;"
                                    class="dropdown-item"
                                >
                                    {{ $t('RepeatApplication') }}
                                </button>
                                
                                <!-- 
                                    Перегляд звернень
                                -->
                                <button 
                                    @click="getAppealsByClient(item.number)"
                                    class="dropdown-item"
                                >
                                    {{ $t('ViewAppeals') }}
                                </button>
                                
                            </div>
                        </div>

                </div>
            </li>
        </ul>

        <div style="display: grid; align-items: end; justify-content: end; margin-top: 10px;">
            <button class="btn btn-soft-info btn-sm" @click="this.showCompManually = !this.showCompManually; removePhone('1')" v-if="this.showComp === false">{{ this.showCompManually === false ? this.$t('Показати більше') : this.$t('showLess') }}</button>
        </div>

        <div class="mt-3 mb-3" v-if="shownum">
            <Multiselect
                v-model="numbers"
                mode="tags"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                :options="numbers"
                @change="findComp($event)"
            />
        </div>

    </b-card>

    <!-- Модальне вікно додати звернення по клієнту -->
    <add_appeal 
        v-if="showAdd_appeal.show"
        @close="showAdd_appeal.show = false"
        :objCard="this.form"
        :clientPhone="clientPhone"
    />

    <!-- Модальне вікно перегляду всіх звернень по клієнту -->
    <review_appeals 
        v-if="showReview_appeal.show"
        @close="showReview_appeal.show = false"
        :objCard="appealsClient"
        :objTicket="this.form"
        :clientPhone="clientPhone"
    />

</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import add_appeal from '../formleft/activity/clients/add_appeal.vue';
import review_appeals from "../formleft/activity/clients/review_appeals.vue";
import { Clients, Tickets, ticketAppeals } from '@/API'
import {copy} from '@/usabilityScripts/copy.js'
import { storeS } from '@/store.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

export let apiServe = new Clients();
export let apiTickets = new Tickets();
let apiAppeals = new ticketAppeals();

export default{
    props: ['obj', 'showComp'],
    components: { 
        Multiselect, 
        add_appeal,
        review_appeals
    },
    data(){
        return{
            showAdd_appeal: {
                data: '',
                show: false
            },
            showReview_appeal: {
                data: '',
                show: false
            },
            count: 0, // рахунок кількості номерів в масиві
            form: {
                userPhones: []
            },
            showCompManually: false,
            objform: {
                accountId: "",
                ticketName: "Не работает акция",
                priority: 0,
                status: "new",
                type: "problem",
                comment: "lol",
                userPhones: ['380631081308']
            },
            shownum: false,
            showblock: true,
            sum: 0,
            showbox: false,
            numbers: [],
            phoneOnRemove: [],
            clientPhone: "",
            appealsClient: {}
        }
    },
    beforeMount(){
        console.log(this.form)
        if(this.form) {
            this.form.userPhones.forEach(el => {
                if(el.number){
                    this.numbers.push(String(el.number))
                } else {
                    this.numbers.push(String(el))
                }
            })
        }
    },
    created(){
        this.getdata();
        // this.getSum()
        // this.getSumDay()
    },
    mounted(){
        this.eventBus.on('popNumber', status => {
            this.removePhone(status.number)
        })

        this.eventBus.on('addNumber', status => {
            this.numbers.push(status.obj)
        })

        this.count = this.form.userPhones.length
    },
    methods:{
        findComp(event){
            if(this.count > event.length){
                this.count = event.length
            } else {
                let customNumber = String(Number(event[event.length-1]))
                var numcode = ["39", "67", "68", "96", "97", "98", "50", "66", "95", "99", "63", "73", "93", "91", "92", "89", "94"]
                numcode.forEach(el => {
                    if(customNumber.substr(0, 2) == el ) {
                        customNumber = this.phoneReset(customNumber)
                    }
                })

                this.eventBus.emit('enterCompanies', {number: customNumber})
                this.count = event.length
            }
        },
        phoneReset(e) {
            return mutatePhone(e)
        },
        removePhone(e){
            console.log(e)
            this.phoneOnRemove.push(e)
            console.log(this.phoneOnRemove)
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        getdata(){
            this.form = this.obj
        },
        getSum(){
            const date = this.form.create_datetime
            var countD = 0
            let currentDate = Date.parse(new Date());
            let days = (currentDate - Date.parse(date))/86400000;       //86400000 - ms в дне
            countD = Math.round(days)
            // this.sum = this.sum * countD
            
            for(var item in this.form.users_phones){
                apiServe.getClient(this.form.users_phones[item]).then(res => {
                    if(res){
                        if(res.data.user_date < this.form.create_datetime){
                            this.sum += Number(res.data.license.summ)/Number(30)*Number(countD)
                        }
                    }
                })
            }
            ("this.sum", this.sum)
            // this.sum = this.sum/30

            this.showbox = true
            
        },
        getSumDay(){
            // const date = '2022-08-10'
            const date = this.form.create_datetime
            var countD = 0
            let currentDate = Date.parse(new Date());
            let days = (currentDate - Date.parse(date))/86400000;       //86400000 - ms в дне
            countD = Math.round(days)
            ("count", countD)
        },
        open(e){
            if(e.companyId && e.companyId != 'none') { // якщо е об'єкт і є айді компанії, але його можна знайти
                this.$emit('openclient', e.companyId.replaceAll('-', ''))
            } else if(e.companyId == 'none' || !e.companyId) { // якщо е об'єкт і є айді компанії, але його не можна знайти
                this.$emit('openclient', e.number)
            } else { // якщо е не об'єкт, то просто передаємо номер
                this.$emit('openclient', e)
            }
        },
        editnum(){

            // Якщо жоден елемент масиву form.userPhones не містить або number або сам елемент, то цей елемент додається до масиву this.form.userPhones 
            const uniqueNumbers = new Set(this.form.userPhones.map(item => (typeof item === 'object' ? item.number : item)));

            console.log('uniqueNumbers', uniqueNumbers);
            this.numbers.forEach(number => {
                const numberToCheck = typeof number === 'object' ? number.number : number;
                console.log('numberToCheck', numberToCheck);
                // Додаємо унікальні номери та об'єкти з унікальними номерами
                if (!uniqueNumbers.has(numberToCheck)) {
                    console.log('typeof number', typeof number + " - " + number);
                    console.log('this.numbers', this.numbers);
                    if (typeof number === 'string') {
                        // Якщо елемент - рядок, то перевіряємо, чи є об'єкт з цим номером
                        const matchingObject = this.numbers.find(obj => typeof obj === 'object' && obj.number === number);
                        console.log('matchingObject', matchingObject);
                        if (matchingObject) {
                            this.form.userPhones.push(matchingObject);
                        } else {
                            this.form.userPhones.push({ number });
                        }
                    } else if (typeof number === 'object' && number.number) {
                        console.log("що елемент - об'єкт і має властивість number, то додаємо його");
                        // Якщо елемент - об'єкт і має властивість number, то додаємо його
                        this.form.userPhones.push(number);
                    }

                    uniqueNumbers.add(numberToCheck);
                }
            });
            this.numbers.forEach(el => {
                if(!(this.form.userPhones.find(k => k.number == el) || this.form.userPhones.find(k => k == el))) {
                    this.form.userPhones.push(el)
                }
            })

            console.log('finalyy numbers', this.form.userPhones)

            // перевірка відсутності номерів

            this.form.userPhones.forEach((el, index) => {
                // перебираємо номери, що актуальні у формі і порівнюємо з тими, що маємо перед відправкою
                if(el.number) {
                    if(this.numbers.indexOf(el.number) === -1) {
                        this.form.userPhones.splice(index, 1)
                    }
                } else {
                    if(this.numbers.indexOf(el) === -1) {
                        this.form.userPhones.splice(index, 1)
                    }
                }
            })
            // перевірка відсутності номерів

            if(!Array.isArray(this.form.userPhones)) {
                this.$toast.error(this.$t('error'));

                var errorText = 'Номери йдуть string'; 
                var statusRequest = "errot";
                var api = 'savePhonesByTicket';
                var fileRequest = 'src/views/newtickets/view/formright/clientnumber.vue';
                var params = this.form.userPhones;
                var response = "numbers is string"

                // Викликаємо функцію для відправки повідомлення в телеграм
                sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                .then(response => console.log('Telegram API response:', response.data))
                .catch(error => console.error('Telegram API error:', error));
                
                
                return
            }

            let obj = {
                userPhones: this.form.userPhones
            }

            apiTickets.savePhonesByTicket(this.form.ticketId, obj).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('addNumber'));
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка при додаванні номерів'; 
                    var statusRequest = result.status;
                    var api = 'savePhonesByTicket';
                    var fileRequest = 'src/views/newtickets/view/formright/clientnumber.vue';
                    var params = `id: ${this.form.ticketId}, form: ${this.form}`;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
            this.shownum = false
        },
        getAppealsByClient(number){
            // Отримання повторних звернень від клієнта

            apiAppeals.getAppealsByClient(this.form.ticketId, number).then(result => {
                    if(result.status == 'done') {
                        this.clientPhone = number
                        this.appealsClient = result.data
                        this.showReview_appeal.show = true;
                    } else {
                        this.$toast.error(result.error[0])
                    }

                }
            )
        }
    },
    mounted(){
        this.eventBus.off('openAppeals');
        this.eventBus.on('openAppeals', (status) => {
            console.log("sd", status)
            this.getAppealsByClient(status.phone);
        })
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        }
    }
}
</script>

<style>
.click{
    cursor: pointer;
}
</style>