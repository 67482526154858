<template>
    <!-- 
        Ліва частина тіла модального вікна
    -->

    <b-card>
        <b-row>
            <b-col>
                <!-- Секція зображення, імені та посади працівника -->
                <div class="card-body p-4 text-center border-bottom pb-3 mb-2">
                    <div class="mx-auto mb-3">
                        <div class="avatar-xl" style="margin: 0 auto;">
                            <div class="avatar-title rounded bg-soft-danger text-danger">
                                {{getInitials(form.workerName)}}
                            </div>
                        </div>
                    </div>
                    <h5 class="card-title mb-1 fw-semibold">{{form.workerName}}</h5> 
                    <p class="text-muted mb-0">{{this.mutateWorkerJob(form.workerJob)}}</p>
                </div>

                <!-- Секція інформації про працівника -->
                <div class="table-responsive">
                    <table class="table table-borderless align-middle mb-0">
                        <tbody>
                            <!-- Рядок з іменем працівника -->
                            <tr>
                                <td class="fw-semibold">{{$t('name')}}</td>
                                <td v-if="statusEdit == false">{{form.workerName}}</td>
                                <td v-else><input type="text" class="form-control" v-model="form.workerName" :placeholder="$t('name')"></td>
                            </tr>
                             <!-- Рядок з номером телефону працівника -->
                            <tr>
                                <td class="fw-semibold">{{ $t('phone_number') }}</td>
                                <td v-if="statusEdit == false">{{form.workerPhone}}</td>
                                <td v-else><input type="text" class="form-control" v-model="form.workerPhone" :placeholder="$t('name')"></td>
                            </tr>
                            <!-- Рядок з поштою працівника -->
                            <tr>
                                <td class="fw-semibold">{{$t('E-mail')}}</td>
                                <td v-if="statusEdit == false">{{form.workerEmail ? form.workerEmail : '-'}}</td>
                                <td v-else><input type="text" class="form-control" v-model="form.workerEmail" :placeholder="$t('name')"></td>
                            </tr>
                            <!-- Рядок з телеграмом працівника -->
                            <tr>
                                <td class="fw-semibold">{{$t('Telegram')}}</td>
                                <td v-if="statusEdit == false">
                                    <a v-if="form.telegramUsername != ''" class="copy link-secondary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover" :href="`https://t.me/${form.telegramUsername}`" target="_blank">
                                        {{ $t('Open') }}
                                    </a>
                                    <span v-else>-</span>
                                </td>
                                <td v-else><input type="text" class="form-control" v-model="form.telegramUsername"></td>
                            </tr>
                            <!-- Рядок з посадою працівника -->
                            <tr>
                                <td class="fw-semibold">{{ $t('Position') }}</td>
                                <td v-if="statusEdit == false">{{this.mutateWorkerJob(form.workerJob)}}</td>
                                <td v-else>
                                    <select v-model="form.workerJob" class="form-control">
                                        <option :value="form.workerJob" selected> {{this.mutateWorkerJob(form.workerJob)}} </option>
                                        <option v-if="form.workerJob !== 'PM'" value="PM"> {{this.mutateWorkerJob('PM')}} </option>
                                        <option v-if="form.workerJob !== 'developer'" value="developer"> {{this.mutateWorkerJob('developer')}} </option>
                                        <option v-if="form.workerJob !== 'managercallcenter'" value="managercallcenter"> {{this.mutateWorkerJob('managercallcenter')}} </option>
                                        <option v-if="form.workerJob !== 'salesmanager'" value="salesmanager"> {{this.mutateWorkerJob('salesmanager')}} </option>
                                        <option v-if="form.workerJob !== 'ceo'" value="ceo"> {{this.mutateWorkerJob('ceo')}} </option>
                                        <option v-if="form.workerJob !== 'tester'" value="tester"> {{this.mutateWorkerJob('tester')}} </option>
                                        <option v-if="form.workerJob !== 'supportmanager'" value="supportmanager"> {{this.mutateWorkerJob('supportmanager')}} </option>
                                        <option v-if="form.workerJob !== 'smm'" value="smm"> {{this.mutateWorkerJob('smm')}} </option>
                                        <option v-if="form.workerJob !== 'marketer'" value="marketer"> {{this.mutateWorkerJob('marketer')}} </option>
                                    </select>
                                </td>
                            </tr>
                            <!-- Рядок зі статусом працівника -->
                            <tr>
                                <td class="fw-semibold">{{ $t('Status') }}</td>
                                <td :class="`badge `+this.mutateWorkerStatus(form.workerStatus).bgcolor+``">{{this.mutateWorkerStatus(form.workerStatus).name}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Кнопка для збереження змін у випадку редагування -->
                <b-button v-if="statusEdit == true" @click="this.$emit('editUser', this.form)" variant="success" class="waves-effect waves-light mt-2 mb-2"><i class="ri-edit-bin-line align-bottom me-1"></i>{{ $t('save') }}</b-button>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { mutateWorkersAccount, mutateWorkerJob } from '@/usabilityScripts/globalMutate.js'
import {copy} from '@/usabilityScripts/copy.js'

export default ({
    props: ['obj', 'statusEdit'],
    data(){
        return {
            form: {},
        }
    },
    created() {
        this.form = this.obj
    },
    methods: {
        getInitials(string) {
            // Метод для отримання ініціалів працівника

            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }

            return initials;
        }, 
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        mutateWorkerStatus(e) {
            /*
                Функція для застосування перекладу до статусу співробітника
            */

            return mutateWorkersAccount(e)
        },
        mutateWorkerJob(e){
            /*
                Функція для застосування перекладу до посади співробітника
            */

            return mutateWorkerJob(e)
        },
    }
})
</script>

<style scoped>
.avatar-title {
    font-size: 30px;
    font-weight: 600;
}

.table-responsive {
    overflow: auto;
}

.table-responsive::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar
{
	width: 5px;
    height: 5px;
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}

</style>