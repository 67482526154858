<template>
    <!--
         Модальне вікно з додавання рішення до тікету 
    -->
    
    <dialogBox @close="this.$emit('close')">
        <!-- Назва вікна -->
        <template v-slot:title>{{$t('Decision')}}</template>

        <!-- Тіло вікна -->
        <template v-slot:body>

            <div class="mb-3" v-if="form.decidedStatus != 'noProblem' && form.decidedStatus != 'fixedForClient'">
                <p class="text-muted mb-2">
                    <a 
                        @click="showModalCreate = true" 
                        class="link copy float-end text-decoration-underline"
                    >
                        {{ $t('Add') }}
                    </a>
                    {{ $t('project') }}
                </p>

                <b-row>

                    <!-- Обрати проект -->
                    <b-col lg="6">
                        <Multiselect
                            v-model="type"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="typeUpdates"
                            @select="getUpdates"
                            :placeholder="this.$t('project')"
                        />
                    </b-col>

                    <!-- Обрати апдейт до цього проекту -->
                    <b-col lg="6">
                        <Multiselect
                            v-model="form.deadlineId"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="deadlinesList"
                            :disabled="type == ''"
                        />
                    </b-col>
                </b-row>
            </div>


            <div class="mb-3">
                <!-- Вказати статус оновлення -->
                <label class="form-label">{{ $t('Status') }}</label>
                <select
                    class="form-select mb-3"
                    data-plugin="choices"
                    v-model="form.decidedStatus"
                    >
                    <option v-for="item in listStatus" :key="item" :value="item.value">{{ item.label }}</option>
                </select>
            </div>

            <!-- Вказати коментар до рішення -->
            <div class="mb-3">
                <label  class="form-label">{{ $t('Comment') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" :placeholder="$t('Decision')"></textarea>
            </div>

            <!-- Вказати алгоритм дій -->
            <!-- <div class="mb-3" v-else>
                <label  class="form-label">{{ $t('describeActions') }}</label>
                <textarea class="form-control" rows="3" v-model="form.comment" :placeholder="$t('describeAlgorithm')"></textarea>
            </div> -->

            <!-- <template v-if="this.typeTicket == 'problem'"> -->

                <!-- Додатково по проблемі -->
                <template v-if="this.typeTicket == 'problem' && (form.decidedStatus == 'waittesting' || form.decidedStatus == 'waitrelease' || form.decidedStatus == 'inrelease' || form.decidedStatus == 'fixedForClient')">

                    <!-- Блок про проблему -->
                    <h5 class="fs-14 mb-3 text-info fw-semibold">{{ $t('AdditionalonProblem') }}</h5>

                    <!-- Вказати причину чому виникла проблема -->
                    <div class="mb-3">
                        <label  class="form-label">{{ $t('WhydidProblemOccur') }}</label>
                        <textarea class="form-control" rows="3" v-model="form.problemIn" :placeholder="$t('WhydidProblemOccur_example')"></textarea>
                    </div>

                    <!-- Чи виникала проблема раніше -->
                    <div class="mb-3">
                        <label class="form-label">{{ $t('HasProblemHappenedBefore') }}</label>

                        <!-- Вибір так чи ні -->
                        <div>
                            <b-form-radio-group
                                v-model="this.form.problemWas"
                                :options="optionsBeforeProblem"
                                class="mb-3"
                                value-field="item"
                                text-field="name"
                                disabled-field="notEnabled"
                            ></b-form-radio-group>
                        </div>

                        <!-- Якщо виникала -->
                        <template v-if="this.form.problemWas == true">

                            <!-- Відколи відома проблема -->
                            <div class="mb-3" >
                                <h4 class="fs-15">{{ $t('HowLonghasProblemKnown') }}?</h4>
                                <b-form-select v-model="this.form.sinceProblemKnown" :options="optionsDateKnownProblem"></b-form-select>
                            </div>

                            <!-- Якщо проблема відома більше ніж місяць, то вказати причину довгого вирішення -->
                            <div class="mb-4" v-if="this.form.sinceProblemKnown != 'several_days' && this.form.sinceProblemKnown != 'week' && this.form.sinceProblemKnown != '' && this.form.sinceProblemKnown != 'day'">
                                <h4 class="fs-15">{{ $t('reasonForLongtermSolutionProblem') }}?</h4>
                                <textarea class="form-control" rows="3" v-model="form.solutionReason" :placeholder="$t('SpecifyReasonWhyProblemTimeSolve')"></textarea>
                            </div>

                        </template>
                    </div>

                </template>

                <!-- Тестінг -->
                <template v-if="form.decidedStatus == 'waittesting' || form.decidedStatus == 'waitrelease' || form.decidedStatus == 'inrelease'">
                   
                    <h5 class="fs-14 mb-3 text-danger fw-semibold">{{ $t('Testing') }}</h5>

                    <div class="mb-3">

                        <label class="form-label">{{ $t('HasSolutionToticketBeenTested') }}</label>

                        <!-- Вибір так чи ні (чи проводилось тестування) -->
                        <div>
                            <b-form-radio-group
                                v-model="this.form.passedTest"
                                :options="optionsBeforeProblem"
                                class="mb-3"
                                value-field="item"
                                text-field="name"
                                disabled-field="notEnabled"
                            ></b-form-radio-group>
                        </div>

                        <!-- Якщо проводилось -->
                        <div class="mb-3 mt-2" v-if="this.form.passedTest == true">

                            <label class="form-label">{{ $t('WhoConductedtheTesting') }}?</label> <br/>

                            <!-- Ким пройшло тестування (вибір співробітників) -->
                            <template v-for="item in qaWorkerList" :key="item"> 
                                <span 
                                    :class="`fs-12 badge bg-${checkWorker(item) ? 'danger' : 'success'} copy`" 
                                    @click="checkWorker(item) ? '' : addWorkers(item)"
                                >
                                    {{ item.label }}
                                </span>
                            </template>
                            
                            <Multiselect 
                                class="mt-3"
                                v-model="form.whoPassedTest"
                                :close-on-select="true"
                                :searchable="true"
                                mode="tags"
                                :options="this.workersList"
                                :placeholder="$t('enterSearchTerm')"
                            />

                            <div class="mb-3 mt-2" v-if="this.form.whoPassedTest.length == 1 && this.form.whoPassedTest[0] == this.user.userid">
                                <b-alert show variant="danger">
                                    {{ $t('PleaseIncludeQA') }}
                                </b-alert>
                            </div>

                            <div class="mb-3 mt-3" >
                                <b-alert show variant="info">
                                    {{ $t('decision_infoAlert') }}
                                </b-alert>
                            </div>

                        </div>

                        <div class="mb-3 mt-2" v-if="this.form.passedTest == false">
                            <b-alert show variant="danger">
                                {{ $t('decision_dangerAlert') }}
                                <br/>
                                <span class="fw-semibold"> {{ $t('decision_dangerAlert_pm') }}</span>
                            </b-alert>
                        </div>

                    </div>
                    
                </template>

            <!-- </template> -->

        </template>

        <template v-slot:footer-bottom>

            <!-- Кнопка скасувати -->
            <a 
                class="btn btn-link link-danger fw-medium" 
                @click="this.$emit('close')"
            >
                <i class="ri-close-line me-1 align-middle"></i> 
                {{ $t('cancel') }} 
            </a>

            <!-- Кнопка додати -->
            <button 
                type="button" 
                class="btn btn-success" 
                @click="toDone()"
            >
                {{ $t('Add') }}
            </button>
        </template>
    </dialogBox>
    
    <!-- Компонент додавання оновлення з вікна рішення -->
    <createDead 
        v-if="showModalCreate == true" 
        @close="this.getUpdates()" 
        :type="this.type"
    />

</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модально вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import createDead from '../../../changelog/new.vue' // компонент створення оновлення
import { Tickets, Updates } from '@/API' // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { sendNotTestedSolutionTicket, sendNotQATestedSolutionTicket } from '@/usabilityScripts/logsTelegram.js'

let apiServe = new Tickets();
let apiUpdates = new Updates();

export default {
    props: ['id', 'typeTicket'],
    components: {
        dialogBox,
        Multiselect,
        createDead
    },
    data(){
        return {
            workersList: [{}], // список співробітників
            qaWorkerList: [], // список qa
            optionsBeforeProblem: [ // опції до чи була відома проблема
                { item: true, name: this.$t('y') },
                { item: false, name: this.$t('n') },
            ],
            sinceProblemKnown: 'several_days',
            optionsDateKnownProblem: [ // опції до відколи відома проблема
                { value: 'day', text: this.$t('day') },
                { value: 'several_days', text: this.$t('Several_days') },
                { value: 'week', text: this.$t('week') },
                { value: 'month', text: this.$t('Month') },
                { value: 'quarter', text: this.$t('Quarter') },
                { value: 'half_year', text: this.$t('half_year') },
                { value: 'year', text: this.$t('Year') },
            ],
            typeUpdates: [
                {
                    label: 'SkyService',
                    value: "skyservice"
                },
                {
                    label: 'Pos-terminal',
                    value: "posterminal"
                },
                {
                    label: 'Dashboard',
                    value: "dashboard"
                },
                {
                    label: 'Sky Market',
                    value: "skymarket"
                },
                {
                    label: 'SellUP',
                    value: "sellup"
                },
                {
                    label: this.$t('orderBoard'),
                    value: "hallscreen"
                },
                {
                    label: 'Inside',
                    value: "inside"
                },
                {
                    label: this.$t('onlyForAndroid'),
                    value: "android"
                },
                {
                    label: this.$t('onlyForIOS'),
                    value: "ios"
                },
            ],
            deadlinesList: [],
            showModalCreate: false,
            form: {
                comment: "", // коментар до рішення
                decidedStatus: "waitrelease", // статус рішення
                deadlineId: '', // айді дедлайну
                whoPassedTest: [], // ким пройшло тестування
                problemIn: "",  // чому виникла проблема
                problemWas: false, // поле буде відповідати на питання чи була проблема
                sinceProblemKnown: "several_days", // відколи проблема відома
                passedTest: false, // пройшло тестування
                solutionReason: "" // причина тривалого вирішення проблеми
            },
            dealinesList: [],
            listStatus: [ //список статусів
                {
                    // Очікує тестування
                    "label": this.$t('waitTesting'),
                    "value": "waittesting"
                },
                {
                    // Оікує на реліз
                    "label": this.$t('waitRelease'),
                    "value": "waitrelease"
                },
                {
                    // В релізі
                    "label": this.$t('inRelease'),
                    "value": "inrelease"
                },
                {
                    // Немає проблеми
                    "label": this.$t('noProblem'),
                    "value": "noProblem"
                },
                {
                    // Виправлено для клієнта
                    "label": this.$t('fixedForClient'),
                    "value": "fixedForClient"
                }
            ],
        }
    },
    created(){
        // Отримання оновлень
        // this.getUpdates();

        // Заповнення списку співробітників для вибору в формі
        for(var item in this.workers){
            this.workersList.push({
                label: this.workers[item].workerName,
                value: this.workers[item].workerId,
            })
        }

        // Заповнення списку співробітників для вибору в формі (QA)
        for(var item in this.workers){
            if(this.workers[item].workerJob == "tester") {
                this.qaWorkerList.push({
                    label: this.workers[item].workerName,
                    value: this.workers[item].workerId,
                })
            }
        }
        
    },
    methods: {
        // changeListUpdate(){
        //     this.deadlinesList = [];
        //     this.deadlines.forEach(element => {
        //             if(element.update == this.type && (new Date().getTime() - new Date(element.deadlineDate + " 23:59").getTime()) <= 0 && element.status != 'in_release'){
        //                 this.deadlinesList.push({
        //                     label: `${this.$t('app')}: ` + element.update.charAt(0).toUpperCase() + element.update.slice(1) + ` | ${this.$t('version')}: ` + element.version + ` | ${this.$t('date')}: ` + element.deadlineDate,
        //                     value: element.deadlineId
        //                 })
        //             }
        //         // } 
        //     });
        // },
        addWorkers(e){
            // Швидке додавання співробітника за межами списку в масив тестерів рішення
            this.form.whoPassedTest.push(e.value)
        },
        checkWorker(e){
            // перевірка наявності співробітника в списку обраних співробітників
            return this.form.whoPassedTest.includes(e.value);
        },
        toDone(){
            /**
                Додає рішення до тікету за допомогою відправки даних до API.
            */

            // йде перевірка на статус рішення
            if (!this.form.decidedStatus) {
                this.$toast.error(this.$t('enterdecidedStatus'));
                return
            }

            // йде перевірка на коментар
            if (!this.form.comment) {
                this.$toast.error(this.$t('EnterComment'));
                return
            }
            if(this.form.decidedStatus && this.form.comment){
                apiServe.decideTicket(this.id, this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(`done`);
                        this.eventBus.emit('UpdateCard', true)
                        this.$emit('close');

                        // Лог додавання рішення до тікету, якщо воно не тестувалось
                        if(this.form.passedTest == false && (this.form.decidedStatus == 'waittesting' || this.form.decidedStatus == 'waitrelease' || this.form.decidedStatus == 'inrelease')){

                            var ticketdId = this.id;

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendNotTestedSolutionTicket(ticketdId)
                            .then(response => {
                                console.log('Telegram API response:', response.data)
                                // this.$toast.success(this.$t('msgSent'))
                                // this.$emit('close')
                            })
                            .catch(error => console.error('Telegram API error:', error)); 

                        }

                        // Лог додавання рішення до тікету, якщо тестувалось тільки розробником
                        if(this.form.passedTest == true && this.form.whoPassedTest[0] == this.user.userid) {

                            var ticketdId = this.id;

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendNotQATestedSolutionTicket(ticketdId)
                            .then(response => {
                                console.log('Telegram API response:', response.data)
                                // this.$toast.success(this.$t('msgSent'))
                                // this.$emit('close')
                            })
                            .catch(error => console.error('Telegram API error:', error)); 
                        }

                    } else {
                        this.$toast.error(`error`);
                    }
                })
            }
        },
        getUpdates(){
            this.dealinesList = []
            // отримання списку оновлень

            if(this.showModalCreate == true) {
                this.showModalCreate = false
            }

            // todo dev (додати вікно "обрати оновлення"), щоб одразу не пушити 1000 і більше в ліміт
            let objParams = {
                pagelimit: '1000',
                update: this.type,
                status: 'new'
            }
            apiUpdates.getAllUpdates(objParams).then(result => {
                if(result.status == 'done'){
                    this.deadlinesList = []
                    this.deadlines = result.data.items
                    this.deadlines.forEach(element => {
                        console.log(element)
                        if(new Date(element.deadlineDate) - new Date() >= 0 && element.status != 'in_release' && element.status != 'pre_release') { // фільтр по даті
                            this.deadlinesList.push({
                                label: `${this.$t('app')}: ` + element.update.charAt(0).toUpperCase() + element.update.slice(1) + ` | ${this.$t('version')}: ` + element.version + ` | ${this.$t('date')}: ` + element.deadlineDate,
                                value: element.deadlineId
                            })
                        } 
                    });
                } else {
                    this.$toast.error(`error`);
                }
            })
        }
    },
    computed: {
        dealines(){
            /*
                Повертає дані оновлень з глобального стору.
            */
            return storeS.dealines
        },
        workers() {
            /*
                Повертає список з усіма користувачами з глобального стору.
            */
            return storeS.workers
        },
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
    }
}
</script>
