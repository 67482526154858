import { storeS } from "@/store";
import i18n from "../i18n";

export default [
  {
    path: "/releases",
    name: "releases",
    meta: { title: i18n.global.t('releases'), authRequired: true },
    component: () => import("@/views/releases/index.vue"),
  },
  {
    path: "/archive/monitoring",
    name: "monitoring",
    meta: { title: i18n.global.t('archiveMonitoring'), authRequired: true },
    component: () => import("@/views/archive/monitoring.vue"),
  },
  {
    path: "/notion",
    name: "Notion",
    meta: { title: i18n.global.t('monitoringLabel'), authRequired: true },
    component: () => import("@/views/notion/index.vue"),
  },
  {
    path: "/monitoring",
    name: "Monitoring",
    meta: { title: i18n.global.t('monitoringLabel'), authRequired: true },
    component: () => import("@/views/monitoring/index.vue"),
  },
  {
    path: "/403",
    name: "permDenide",
    meta: { title: 403, authRequired: true },
    component: () => import("@/views/403/403.vue"),
  },
  {
    path: "/",
    name: "default",
    meta: { title: i18n.global.t('startPage'), authRequired: true },
    component: () => import("@/views/dashboard/dashboard.vue"),
  },
  {
    path: "/calls/all",
    name: "calls-all",
    meta: { title: i18n.global.t('calls_all'), authRequired: true, perms: 100},
    component: () => import("../views/calls/all"),
  },
  {
    path: "/calls/cold",
    name: "cold-calls",
    meta: { title: i18n.global.t('ColdCalls'), authRequired: true, perms: 100},
    component: () => import("../views/calls/cold/folders/index"),
    // component: () => import("../views/calls/cold/all"),
  },
  {
    path: '/calls/cold/:id',
    name: 'pages-list-open-coldCall',
    props: true,
    meta: { title: i18n.global.t('ColdCalls'), authRequired: true },
    component: () => import('../views/calls/cold/all'),
  },
  {
    path: "/calls/triggers",
    name: "triggers_calls",
    meta: { title: i18n.global.t('triggerCall'), authRequired: true, perms: 100},
    component: () => import("../views/calls/trigger/all"),
  },
  {
    path: "/calls/dumps",
    name: "calls-dumps",
    meta: { title: i18n.global.t('dumps'), authRequired: true, perms: 100 },
    component: () => import("../views/dumps/index"),
  },
  {
    path: "/tasks/all",
    name: "tasks-all",
    meta: { title: i18n.global.t('all_tasks'), authRequired: true, perms: 200 },
    component: () => import("../views/tasks/all"),
  },
  {
    path: "/newtickets/all",
    name: "newtickets-all",
    meta: { title: i18n.global.t('tickets'), authRequired: true, perms: 300 },
    component: () => import("../views/newtickets/all"),
  },
  {
    path: "/projects/all",
    name: "projects",
    meta: { title: i18n.global.t('navProjects'), authRequired: true, perms: 400 },
    component: () => import("../views/projects/all"),
  },
  {
    path: "/clients/pricing",
    name: "pricing",
    meta: { title: i18n.global.t('Prices'), authRequired: true, perms: 900 },
    component: () => import("../views/clients/pricing"),
  },
  {
    path: "/knowledge-base/KnowledgeBase",
    name: "KnowledgeBase",
    meta: { title: i18n.global.t('Knowledge_base'), authRequired: true, perms:800 },
    component: () => import("../views/knowledge-base/KnowledgeBase"),
  },
  {
    path: '/knowledge-base/KnowledgeBase/:id',
    name: 'pages-open-project-kw',
    props: true,
    meta: { title: i18n.global.t('ColdCalls'), authRequired: true },
    component: () => import('../views/knowledge-base/KnowledgeBase'),
  },
  {
    path: "/knowledge-base/KnowledgeBase/:slug",
    name: "pages-knowledge-base-lesson",
    meta: { title: i18n.global.t('Knowledge_base'), authRequired: true, perms:800 },
    component: () => import("@/components/knowledge-base/view/index"),
  },
  {
    path: "/shortcuts",
    name: "shortcuts",
    meta: { title: i18n.global.t('answerLabels'), authRequired: true, perms:1100 },
    component: () => import("../views/shortcuts/index"),
  },
  {
    path: "/faq/faq",
    name: "faq",
    meta: { title: i18n.global.t('qua_and_ans'), authRequired: true, perms:1000 },
    component: () => import("../views/faq/faq"),
  },
  {
    path: "/archive/applications",
    name: "applications",
    meta: { title: i18n.global.t('archiveApplic'), authRequired: true, perms: 400 },
    component: () => import("../views/archive/applications"),
  },
  {
    path: "/archive/dumps",
    name: "dumps",
    meta: { title: i18n.global.t('archiveDumps'), authRequired: true, perms: 400 },
    component: () => import("../views/archive/dumps"),
  },
  {
    path: "/archive/presentations",
    name: "archivepresentations",
    meta: { title: i18n.global.t('archivePresentations'), authRequired: true, perms: 400 },
    component: () => import("../views/archive/presentations"),
  },
  {
    path: "/archive/tasks",
    name: "tasks",
    meta: { title: i18n.global.t('archiveTasks'), authRequired: true, perms: 400 },
    component: () => import("../views/archive/tasks"),
  },
  {
    path: "/archive/tickets",
    name: "tickets",
    meta: { title: i18n.global.t('archiveTickets'), authRequired: true, perms: 400 },
    component: () => import("../views/archive/tickets"),
  },
  {
    path: "/archive/projects",
    name: "Archive Projects",
    meta: { title: i18n.global.t('archiveProjects'), authRequired: true, perms: 400 },
    component: () => import("../views/archive/projects"),
  },
  {
    path: "/archive/coldCalls",
    name: "Archive ColdCalls",
    meta: { title: i18n.global.t('archiveColdCalls'), authRequired: true, perms: 400 },
    component: () => import("../views/archive/coldCalls"),
  },
  {
    path: "/archive/shifts",
    name: "ashifts",
    meta: { title: i18n.global.t('archiveShift'), authRequired: true, perms: 400 },
    component: () => import("../views/archive/shifts"),
  },
  {
    path: "/archive/sms",
    name: "sms",
    meta: { title: i18n.global.t('archiveSms'), authRequired: true, perms: 400 },
    component: () => import("../views/archive/sms"),
  },
  {
    path: "/users",
    name: "users",
    meta: { title: i18n.global.t('users'), authRequired: true, perms: 700 },
    component: () => import("../views/users/users"),
  },
  {
    path: "/dashboard/new",
    name: "dashboard-projects",
    meta: {
      title: "Projects", authRequired: true,
    },
    component: () => import("../Dashboard.vue"),
  },
  {
    path: "/invoices/all",
    name: "invoices-all",
    meta: { title: i18n.global.t('invoices'), authRequired: true, perms: 1700 },
    component: () => import("../views/invoices/all"),
  },
  {
    path: "/services/all",
    name: "services",
    meta: { title: i18n.global.t('Services'), authRequired: true, perms: 510 },
    component: () => import("../views/services/all"),
  },
  {
    path: "/ecommerce/products",
    name: "ecommerce-products",
    meta: { title: i18n.global.t('Products'), authRequired: true, perms:1300 },
    component: () => import("../views/ecommerce/products"),
  },
  {
    path: "/ecommerce/categories",
    name: "ecommerce-categories",
    meta: { title: i18n.global.t('Products'), authRequired: true, perms: 1400 },
    component: () => import("../views/ecommerce/categories/list"),
  },
  {
    path: "/ecommerce/providers",
    name: "ecommerce-providers",
    meta: { title: i18n.global.t('ProductCategories'), authRequired: true, perms: 1500 },
    component: () => import("../views/ecommerce/providers/list"),
  },
  {
    path: "/ecommerce/attributes",
    name: "ecommerce-attributes",
    meta: { title: i18n.global.t('attributes'), authRequired: true, perms: 1500 },
    component: () => import("../views/ecommerce/attributes/list"),
  },
  {
    path: "/ecommerce/settings",
    name: "ecommerce-settings",
    meta: { title: i18n.global.t('Settings'), authRequired: true, perms: 1500 },
    component: () => import("../views/ecommerce/settings/index"),
  },
  {
    path: "/ecommerce/orders",
    name: "ecommerce-orders",
    meta: { title: i18n.global.t('Orders'), authRequired: true, perms: 1500 },
    component: () => import("../views/ecommerce/orders/index"),
  },
  {
    path: "/ecommerce/customers",
    name: "ecommerce-customers",
    meta: { title: i18n.global.t('clients'), authRequired: true, perms: 1500 },
    component: () => import("../views/ecommerce/customers/index"),
  },
  {
    path: '/auth/lockscreen-basic',
    name: 'lock-screen-basic',
    meta: {
      title: i18n.global.t('LockScreen'), authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/basic')
  },
  {
    path: '/docs',
    name: 'docs',
    meta: {
      title: i18n.global.t('docs'), authRequired: true,
    },
    component: () => import('../views/docs/index')
  },
  {
    path: '/attributesdocs',
    name: 'attributesdocs',
    meta: {
      title: i18n.global.t('attribute-docs'), authRequired: true,
    },
    component: () => import('../views/docs/attributesdocs/list')
  },
  {
    path: '/apidocs',
    name: 'apidocs',
    meta: {
      title: 'API', authRequired: true,
    },
    component: () => import('../views/docs/apidocs/list')
  },
  {
    path: '/changelog',
    name: 'changelog',
    meta: {
      title: i18n.global.t('Updates'), 
      authRequired: true,
      perms: 370
    },
    component: () => import('../views/changelog/list')
  },
  {
    path: "/calls/other",
    name: "calls-other",
    meta: { title: i18n.global.t('other'), authRequired: true },
    component: () => import("../views/calls/other/other"),
  },
  {
    path: '/calls/other/:id',
    name: 'pages-list-open-otherCall',
    props: true,
    meta: { title: i18n.global.t('other'), authRequired: true },
    component: () => import('../views/calls/other/listopen'),
  },
  {
    path: "/statistics/callcentre",
    name: "call-centre",
    meta: { title: i18n.global.t('callcenterStatistics'), authRequired: true, perms: 500 },
    component: () => import("../views/statistics/callcentre/index"),
  },
  {
    path: "/statistics/dev",
    name: "dev",
    meta: { title: i18n.global.t('develStatistics'), authRequired: true, perms:500 },
    component: () => import("../views/statistics/dev/index"),
  },
  {
    path: "/statistics/all",
    name: "all",
    meta: { title: i18n.global.t('usersStatistics'), authRequired: true, perms:500 },
    component: () => import("../views/statistics/all/index"),
  },
  {
    path: "/statistics/sales",
    name: "sines",
    meta: { title: i18n.global.t('Sales'), authRequired: true, perms:500 },
    component: () => import("../views/statistics/sales/index"),
  },
  {
    path: "/statistics/fines",
    name: "fines",
    meta: { title: i18n.global.t('Fines'), authRequired: true, perms:500 },
    component: () => import("../views/statistics/fines/index"),
  },
  {
    path: "/statistics/shifts",
    name: "shifts",
    meta: { title: i18n.global.t('shifts'), authRequired: true, perms:500 },
    component: () => import("../views/statistics/shifts/index"),
  },
  {
    path: "/statistics/holidays",
    name: "holidays",
    meta: { title: i18n.global.t('timeOff'), authRequired: true, perms:520 },
    component: () => import("../views/statistics/holidays/index"),
  },
  {
    path: "/presentations",
    name: "presentations",
    meta: { title: i18n.global.t('presentations'), authRequired: true, perms: 110 },
    component: () => import("../views/presentations/list"),
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: { title: i18n.global.t('Calendar'), authRequired: true, perms: 420 },
    component: () => import("../views/calendar/index2"),
  },
  {
    path: "/accounts/allAccounts",
    name: "allAccounts",
    meta: { title: i18n.global.t('AllAccounts'), authRequired: true, perms:1810 },
    component: () => import("../views/accounts/allAccounts/index"),
  },
  {
    path: "/accounts/unregistered",
    name: "unregistered",
    meta: { title: i18n.global.t('Unregistered'), authRequired: true, perms: 1820 },
    component: () => import("../views/accounts/unregistered/index"),
  },
  {
    path: "/accounts/accountsDeleted",
    name: "accountsDeleted",
    meta: { title: i18n.global.t('ForRemoval'), authRequired: true, perms: 1820 },
    component: () => import("../views/accounts/accountsDeleted/index"),
  },
  {
    path: "/registrations/completed",
    name: "completed",
    meta: { title: i18n.global.t('registrationCompleted'), authRequired: true, perms: 1850 },
    component: () => import("../views/registrations/completed/index"),
  },
  {
    path: "/companies/allCompanies",
    name: "allCompanies",
    meta: { title: i18n.global.t('AllCompanies'), authRequired: true, perms: 1820 },
    component: () => import("../views/companies/allCompanies/index"),
  },
  {
    path: "/companies/companyDeleted",
    name: "companyDeleted",
    meta: { title: i18n.global.t('ForRemoval'), authRequired: true, perms: 1850 },
    component: () => import("../views/companies/companyDeleted/index"),
  },
  {
    path: "/note",
    name: "note",
    meta: { title: i18n.global.t('note'), authRequired: true, perms: 420 },
    component: () => import("../views/note/index"),
  },
  {
    path: "/logs",
    name: "logs",
    meta: { title: i18n.global.t('Logs'), authRequired: true, perms: 2000 },
    component: () => import("../views/logs/index"),
  },
  {
    path: "/filemanager",
    name: "filemanager",
    meta: { title: i18n.global.t('fileManager'), authRequired: true, perms: 950 },
    component: () => import("../views/filemanager/index"),
  },
  {
    path: '/filemanager/:id',
    name: 'pages-list-open-file',
    props: true,
    meta: { title: i18n.global.t('fileManager'), authRequired: true, perms: 950 },
    component: () => import('../views/filemanager/index'),
  },
  {
    path: '/translations',
    name: 'translations',
    meta: {
      title: i18n.global.t('translations'), authRequired: true, perms: 2000
    },
    component: () => import('../views/translator/translations/index')
  },
  {
    path: '/projectStatistics',
    name: 'projectStatistics',
    meta: {
      title: i18n.global.t('projectStatistics'), authRequired: true, perms: 2000
    },
    component: () => import('../views/translator/projectStatistics/index')
  },
  {
    path: '/testing',
    name: 'testing',
    meta: {
      title: i18n.global.t('Testing'), authRequired: true, perms: 2000
    },
    component: () => import('../views/testing/list')
  },
  {
    path: '/software-testing',
    name: 'software-testing',
    meta: {
      title: i18n.global.t('Testing v2'), authRequired: true, perms: 666
    },
    component: () => import('../views/software-testing/index')
  },
  {
    path: '/software-testing/bug-reports',
    name: 'bug-reports',
    meta: {
      title: i18n.global.t('Bug-reports'), authRequired: true, perms: 666
    },
    component: () => import('../views/software-testing/bug-reports/index')
  },
  {
    path: '/software-testing/bug-tracker',
    name: 'bug-tracker',
    meta: {
      title: i18n.global.t('Bug-tracker'), authRequired: true, perms: 666
    },
    component: () => import('../views/software-testing/bug-tracker/index')
  },
  {
    path: '/messages',
    name: 'messages',
    meta: {
      title: i18n.global.t('Message'), authRequired: true, perms: 1900
    },
    component: () => import('../views/messages/index')
  },
  {
    path: "/jobs/candidate",
    name: "candidate",
    meta: { title: i18n.global.t('candidate'), authRequired: true, perms:2000 },
    component: () => import("../views/jobs/candidate/index"),
  },
];
