<template>
    <!-- 
        Модальне вікно створення картки співробітника
    -->

    <modal>
        <!-- Слот шапки модального вікна  -->
        <template v-slot:title>{{ $t('Add') }}</template>

        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <!-- Кнопка для закриття модального вікна, та збереження інформації, щоб продовжити її потім -->
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <!-- Слот тіло модального вікна з формою для введення даних -->
        <template v-slot:body>
            <div class="row">
                <div class="mb-3">
                    <!-- Селектор вибору посади співробітника -->
                    <h4 class="fs-15">{{ $t('Position') }}</h4>
                    <Multiselect 
                        v-model="form.workerJob"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="false"
                        :options="this.jobs_title"
                    />
                </div>
                <div class="mb-3">
                    <!-- Поле для введення імені співробітника -->
                    <h4 class="fs-15">{{ $t('name') }}</h4>
                    <input type="text" class="form-control" v-model="form.workerName" :placeholder="$t('name')">
                </div>
                <div class="mb-3">
                    <!-- Поле для введення пошти співробітника -->
                    <h4 class="fs-15">{{ $t('enterMail') }}</h4>
                    <input type="mail" class="form-control" id="inputEmail4" v-model="form.workerEmail"  placeholder="Email">
                </div>
                <div class="mb-3">
                    <!-- Поле для введення номеру телефона співробітника -->
                    <h4 class="fs-15">{{ $t('enterPhoneNumber') }}</h4>
                    <input type="number" class="form-control" v-model="form.workerPhone" :placeholder="$t('phone_number')">
                </div>
                <div class="mb-3">
                    <!-- Поле для введення телеграму співробітника -->
                    <h4 class="fs-15">{{ $t("EnterTelegramUsername") }}</h4>
                    <input type="text" class="form-control" v-model="form.telegramUsername" >
                </div>
            </div>
        </template>
        <!-- Слот футера модального вікна з кнопкою для збереження даних -->
        <template v-slot:footer-bottom>
            <!-- <a class="btn btn-link link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </a> -->
            <button type="button" class="btn btn-success" v-on:click="add">{{ $t('Add') }}</button>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small' // компонент модального вікна
import Multiselect from "@vueform/multiselect"; // компонент мультіселектора
import "@vueform/multiselect/themes/default.css"; // стилі до компоненту мультіселектора
import { Users } from '@/API.js'; // клас API, який викорстовується для запиту
import { storeS } from '@/store.js' // Глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Users();

export default ({
    data(){
        return {
            form: { // Дані для форми
                workerName: "",  // ім'я юзера
                workerJob: "supportmanager", // посада юзера
                workerEmail: "", // пошта юзера
                workerPhone: "", // номер телефону юзера
                telegramUsername: "" // телеграм юзера
            },
            perms: [],
            jobs_title: [ // Перелік можливих посад співробітників
                {
                    // менеджер кол-центру , підтримка
                    "label": this.$t('PM'),
                    "value": "PM"
                },
                {
                    // менеджер кол-центру , підтримка
                    "label": this.$t('managerCallCenter'),
                    "value": "managercallcenter"
                },
                {
                    // менеджер продажу
                    "label": this.$t('SalesManager'),
                    "value": "salesmanager"
                },
                {
                    // сапорт менеджер, підтримка
                    "label": this.$t('SupportManager'),
                    "value": "supportmanager"
                },
                {
                    // розробник
                    "label": this.$t('Developer'),
                    "value": "developer"
                },
                {
                    // ceo/керівник
                    "label": this.$t('CEO'),
                    "value": "ceo"
                },
                {
                    // тестувальник
                    "label": this.$t('Tester'),
                    "value": "tester"
                },
                {
                    // smm
                    "label": this.$t('SMM'),
                    "value": "smm"
                },
                {
                    // маркетолог
                    "label": this.$t('Marketer'),
                    "value": "marketer"
                },
            ],
        }
    },
    components: { modal, Multiselect },
    created(){
    },
    methods: {
        add(){
            // Функція для додавання нового співробітника

            if (!this.form.workerName) {
                // Перевірка наявності введення імені
                this.$toast.error(this.$t('EnterNameWorker'));
            }
            if (!this.form.workerJob) {
                // Перевірка наявності введення посади
                this.$toast.error(this.$t('EnterWorkerJob'));
            }
            if (!this.form.workerEmail) {
                // Перевірка наявності введення пошти
                this.$toast.error(this.$t('EnterMail'));
            }
            if (!this.form.workerPhone) {
                // Перевірка наявності введення номеру телефону
                this.$toast.error(this.$t('EnterNumberWorker'));
            } 

            // Обробка Telegram Username
            let username = this.form.telegramUsername.trim(); // Видаляємо зайві пробіли

            // Перевіряємо, чи це посилання на Telegram
            if (username.startsWith('https://t.me/')) {
                username = username.replace('https://t.me/', '');
            }

            // Якщо починається з "@", видаляємо його
            if (username.startsWith('@')) {
                username = username.slice(1); // Видаляємо перший символ "@"
            }

            // Призначаємо оброблений нік назад у форму
            this.form.telegramUsername = username;
            
            // Виклик API для додавання нового співробітника
            if (this.form.workerName && this.form.workerJob && this.form.workerEmail && this.form.workerPhone){
                apiServe.addWorker(this.form).then(res => {
                    if(res.status == "done"){
                        this.$toast.success(this.$t('done')); // Виведення повідомлення про успішне додавання
                        this.$emit('close'); // Закриття модального вікна
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var errorText = 'Помилка додавання картки співробітника'; 
                        var statusRequest = result.status;
                        var api = 'addWorker';
                        var fileRequest = 'src/views/users/newuser.vue';
                        var params = this.form;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    }
                })    
            }        
        },
    },
    computed: {
        // Властивість, яка повертає дані юзера з глобального сховища
        user() {
            return storeS.userbase
        }
    }
})
</script>