<template>

    <!-- 
        Компонент модального вікна картки баг-репорту
    -->

    <modal :title="'Баг-репорт'" @close="this.$emit('close')">
    
        <template v-slot:body>

            <b-row>

                <b-col lg="8">

                    <b-card>

                        <!-- Опис баг-репорту -->
                        <div class="text-muted" >
                            <h5 class="mb-3 fw-semibold text-uppercase">Опис</h5>
                            
                            <div class="ql-container">
                                <div class="ql-editor" v-html="form.short_description">
                                </div>
                            </div>
                        </div>

                        <!-- Кроки -->
                        <div class="preconditions mt-4">
                            <div class="header-line-container">
                                <h6 class="fw-semibold text-uppercase">Кроки</h6>
                                <hr>
                            </div>
                            <ul class="ps-4 vstack gap-2 mt-3" style="list-style-type: decimal;">
                                <template v-for="item in form.steps" :key="item">
                                    <li>{{ item.text }}</li>
                                </template>
                            </ul>
                        </div>

                        <!-- Очікуваний результат -->
                        <div class="preconditions mt-4">
                            <div class="header-line-container">
                                <h6 class="fw-semibold text-uppercase">Очікуваний результат</h6>
                                <!-- <hr> -->
                            </div>
                            <span class="ps-2 vstack gap-2 mt-3" >
                                {{ form.expected_result }}
                            </span>
                        </div>

                        <!-- Фактичний результат -->
                        <div class="preconditions mt-4 mb-3">
                            <div class="header-line-container">
                                <h6 class="fw-semibold text-uppercase">Фактичний результат</h6>
                                <!-- <hr> -->
                            </div>
                            <span class="ps-2 vstack gap-2 mt-3" >
                                {{ form.actual_result }}
                            </span>
                        </div>

                    </b-card>

                    <div class="card" >
                        <div class="card-body" >
                            <div class="d-flex align-items-center mb-4" >
                                <h5 class="card-title flex-grow-1 mb-0">{{ $t('Files') }}</h5>
                                <!-- <div class="flex-shrink-0" >
                                    <input class="form-control d-none" type="file" id="formFile">
                                    <label for="formFile" class="btn btn-danger"><i class="ri-upload-2-fill me-1 align-bottom"></i> {{ $t('downloadFiles') }}</label>
                                </div> -->
                            </div>
                            <template v-for="item in form.attachments" :key="item">
                                <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2 mt-3" >
                                    <div class="avatar-sm copy" >
                                        <div class="avatar-title bg-light text-info rounded fs-24" ><i class="ri-video-fill"></i></div>
                                    </div>
                                    <div class="flex-grow-1 ms-3 copy" >
                                        <h6 class="mb-1"><a>{{ item.filename }}</a></h6>
                                        <small class="text-muted">2024-08-29 17:42:03</small>
                                    </div>
                                    <div class="hstack gap-3 fs-16" >
                                        <button class="copy btn btn-soft-info btn-icon"><i class="ri-download-2-line"></i></button>
                                        <button class="copy btn btn-soft-danger btn-icon "><i class="ri-delete-bin-line"></i></button>
                                    </div>
                                </div>
                            </template>
                            
                        </div>
                    </div>

                </b-col>

                <b-col lg="4">
                    
                    <div class="card" >
                        <div class="card-header align-items-center d-flex border-bottom-dashed" >
                            <h4 class="card-title mb-0 flex-grow-1">Деталі</h4>
                            <!-- <div class="flex-shrink-0" >
                                <button type="button" class="btn btn-soft-danger btn-sm" data-bs-toggle="modal" data-bs-target="#inviteMembersModal"><i class="ri-share-line me-1 align-bottom"></i> Invite Member</button>
                            </div> -->
                        </div>
                        <div class="card-body">
                            <div class="table-responsive table-card" >
                                <table class="table table-borderless align-middle mb-0">
                                    <tbody>
                                        <tr>
                                            <td class="fw-medium">Баг-репорт</td>
                                            <td>#<span id="t-no">12</span> </td>
                                        </tr>
                                        <tr>
                                            <td class="fw-medium">Серйозність дефекту</td>
                                            <td><span id="t-no">{{ mutateSeriousnessDefect(form.seriousness_defect).name }}</span> </td>
                                        </tr>
                                        <tr>
                                            <td class="fw-medium">Пріоритет</td>
                                            <td id="t-client"><span :class="mutatepriority(form.priority).color">{{ mutatepriority(form.priority).name }}</span></td>
                                        </tr>
                                        <tr>
                                            <td class="fw-medium">Версія фікс:</td>
                                            <td>
                                                1.22
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fw-medium">Дата створення</td>
                                            <td id="c-date">11 вересня, 2024</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="card" >
                        <div class="card-header align-items-center d-flex border-bottom-dashed" >
                            <h4 class="card-title mb-0 flex-grow-1">Середовище тестування</h4>
                            <!-- <div class="flex-shrink-0" >
                                <button type="button" class="btn btn-soft-danger btn-sm" data-bs-toggle="modal" data-bs-target="#inviteMembersModal"><i class="ri-share-line me-1 align-bottom"></i> Invite Member</button>
                            </div> -->
                        </div>
                        <div class="card-body">
                            
                            <template v-for="item in form.environment" :key="item">

                                <div class="vstack gap-2 mt-2" >
                                    <div class="border rounded border-dashed p-2" >
                                        <div class="d-flex align-items-center" >
                                            <div class="flex-shrink-0 me-3" >
                                                <div class="avatar-sm" >
                                                    <div :class="`avatar-title bg-light text-${item.app_testing == 'browser' ? mutateEnvironmentReport(item.browser).color : mutateEnvironmentReport(item.os).color} rounded fs-24`" >
                                                        <i :class="item.app_testing == 'browser' ? mutateEnvironmentReport(item.browser).icon : mutateEnvironmentReport(item.os).icon"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1 overflow-hidden" >
                                                <h5 class="fs-13 mb-1">
                                                    <a class="text-body text-truncate d-block">{{ mutateEnvironmentReport(item.os).name + " " + item.version_os}}</a>
                                                </h5>
                                                <div >Версія: <span v-if="item.app_testing == 'browser'">{{ item.app_testing ? mutateEnvironmentReport(item.browser).name : '' }}</span> {{ item.ver_app }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </template>
                            
                        </div>
                    </div>

                </b-col>

            </b-row>
            
        </template>
    
    </modal>
    
</template>

<script>
import modal from '@/components/modal/modal-lg' // компонент модального вікна
import { mutateSeriousnessDefect, mutatePriorityTicket, mutateEnvironmentReport } from '@/usabilityScripts/globalMutate'


export default {
    props: ['objCard'],
    components: {
        modal
    },
    data() {
        return {
            form: ''
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        mutateSeriousnessDefect(e){
            // мутейт серйозності дефекту
            return mutateSeriousnessDefect(e)
        },
        mutateEnvironmentReport(e){
            // мутейт середовища тестування
            return mutateEnvironmentReport(e)
        },
        mutatepriority(e){
            // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
    }
}
</script>

<style scoped>
.header-line-container {
    display: flex;
    align-items: center;
}

.header-line-container h6 {
    margin: 0;
    padding-right: 10px; /* Відступ між заголовком і лінією */
}

.header-line-container hr {
    flex-grow: 1;
    border: none;
    /* border-top: 2px solid #000; /* Можна змінити товщину та колір лінії */
    margin: 0;
}
</style>