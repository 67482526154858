<template>
    <modal :key="componentKey" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @close="this.$emit('close')">
        <template v-slot:title>
            {{ this.$t('monitoringLabel') }} #{{ this.form.monitoringId }} 
            ({{ this.findDate() }} {{ $t('days') }})
        </template>
        <template v-slot:status></template>
        <template v-slot:head-button>
            <button 
                v-if="this.form.status !== 'stop'"
                class="btn btn-success" 
                type="button" 
                @click="this.showCommentBox = true"
            >
                {{ $t('addCom') }}
            </button>
            <button 
                v-if="
                    this.form.status !== 'stop' && 
                    this.form.monitoring_type === 'payments' &&
                    this.form.companyId &&
                    this.showTariff === false
                "
                class="btn btn-info" 
                type="button" 
                @click="this.getCompPayment(this.form.companyId)"
            >
                {{ $t('getBalance') }}
            </button>
            <div class="dropdown" v-if="this.showTariff === true">
                <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ $t('getBalance') }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li class="dropdown-item">
                        {{ $t('Paid_to') }}: <span :style="this.checkDate(this.tariff.active)">
                            {{ this.tariff.active }}
                        </span>
                    </li>
                    <li class="dropdown-item">
                        {{ $t('balance') }}: {{ this.tariff.userBalance }}
                    </li>
                    <li class="dropdown-item">
                        {{ $t('SubscriptionFee') }}: {{ this.tariff.tariffCost }}
                    </li>
                </ul>
            </div>
            <div 
                class="dropdown" 
                v-if="
                    this.form.status !== 'stop'&&
                    this.blockEdit !== true
                "
            >
                <!-- Кнопка зі стрілкою для дій з уроком -->
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <!-- Випадаюче меню з діями для уроку -->
                <div class="dropdown-menu dropdown-menu-end">
                    <!-- Редагувати, якщо співробітник є призначенний, тільки він може це робити -->
                    <button 
                        v-if="
                            this.perms[2031] === true
                        "
                        class="dropdown-item" 
                        @click="this.$emit('edit', this.form)"
                    >{{ $t('Edit') }}</button>

                    <!-- Додати заявку -->
                    <button 
                        class="dropdown-item" 
                        @click="this.$emit('addApplic', this.form)"
                    >{{ $t('AddApplication') }}</button>
                </div>
            </div>
        </template>
        <template v-slot:body>
            <!-- Модалка додавання співробітників -->
            <modalMembers
                v-if="showModal"
                :isInviteOne="true"
                @close="showModal = false"
                @inviteOne="inviteOne"
                @open="open"
                :hideNotForYuraButtons="true"
                :projectWorkers="[]"
            />

            <div class="row">
                <div class="col-md-6">
                    <about :obj="this.form" @getClient="getClient"/> 
                </div>
                <div class="col-md-6">
                    <workInfo :obj="this.form" @getClient="getClient"/>
                </div>
            </div>
            <div class="row" v-if="this.form.status === 'stop'" >
                <div class="col-md-12">
                    <close :obj="this.form"/>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <commentForm :obj="this.form.comments" :number="this.form.number"/> 
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <!-- Закінчити моніторинг -->
            <button 
                class="btn btn-danger" 
                v-if="
                    this.form.status !== 'stop' && 
                    this.form.assignedWorkers.length === 0
                " 
                @click="showCloseBox = true"
            >
                {{ this.$t('Stop') }}
            </button>
            
            <button 
                class="btn btn-danger" 
                v-if="
                    this.form.status !== 'stop' && 
                    this.form.assignedWorkers.length > 0 && 
                    this.form.assignedWorkers[0].workerId === this.user.userid
                " 
                @click="showCloseBox = true"
            >   
                {{ this.$t('Stop') }}
            </button>

            <!-- Призначити співробітника на моніторинг -->
            <button 
                class="btn btn-warning" 
                v-if="
                    this.form.status !== 'stop' && 
                    this.perms[2031] === true
                " 
                @click="showModal = true"
            >
                {{ this.$t('Appoint') }}
            </button>

            <!-- Перевірити моніторинг -->
            <button 
                class="btn btn-success" 
                v-if="
                    this.form.status !== 'stop' && 
                    this.form.assignedWorkers.length === 0
                " 
                @click="this.checkMonitoring()"
            >
                {{ this.$t('check') }}
            </button>

            <button 
                class="btn btn-success" 
                v-if="
                    this.form.status !== 'stop' && 
                    this.form.assignedWorkers.length > 0 && 
                    this.form.assignedWorkers[0].workerId === this.user.userid
                " 
                @click="this.checkMonitoring()"
            >   
                {{ this.$t('check') }}
            </button>
        </template>
    </modal>

    <!-- Вікно коменту для закриття моніторингу -->
    <closeBox 
        v-if="showCloseBox === true"    
        :id="this.form.monitoringId"
        @toStop="stopMonitoring"
        @close="showCloseBox = false"
    />
    
    <!-- Вікно коменту для закриття моніторингу -->
    <commentBox 
        v-if="showCommentBox === true"  
        :id="this.form.monitoringId"
        :obj="this.form"
        @close="showCommentBox = false"
        @updateCard="updateCard"
    />

    <!-- Видалити моніторинг -->
    <dialodel
        v-if="this.dialogshow == true" 
        @close="this.dialogshow = false" 
        :obj="this.form" 
        @remove="remove" 
    />

</template>

<script>
import close from './blocks/close.vue'
import dialodel from '@/components/globaltable/actions/dialogdelete.vue'
import commentBox from "./actions/addComment"
import modalMembers from '@/components/modal/modal-members'
import closeBox from "./actions/stopMonitoring.vue"
import modal from '@/components/modal/modal-lg'
import about from './blocks/about.vue';
import workInfo from './blocks/workInfo.vue';
import commentForm from './blocks/commentForm.vue';
import { SuperAdmin, Monitoring } from '../../../API';
import {storeS} from "@/store.js"
import { number } from 'echarts'

let apiServe = new Monitoring()
let apiAdmin = new SuperAdmin();

export default {
    data() {
        return {
            showCloseBox: false,
            showCommentBox: false,
            form: {},
            showModal: false,
            componentKey: 0,
            dialogshow: false,
            tariff: {
                active: '',
                userBalance: '',
                tariffCost: ''
            },
            showTariff: false,
        }
    },
    props: ['obj', 'blockEdit'],
    components: {
        modalMembers,
        closeBox,
        modal,
        about,
        workInfo,
        commentForm,
        commentBox,
        dialodel,
        close
    },
    created() {
        this.form = this.obj

        this.eventBus.off('updateMonitoringCard')
        this.eventBus.on('updateMonitoringCard', () => {
            this.updateCard()
        })
    },
    methods: {
        findDate() {
            let today = new Date()

            return (new Date(today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate() + " 03:00") - new Date(this.form.createDatetime.split(' ')[0]))/24/3600/1000
        },
        checkDate(el) {
            let today = new Date()
            if(new Date(el) > new Date(today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate())) {
                return 'color: green'
            } else if(new Date(el) === new Date(today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate())) {
                return 'color: orange'
            } else {
                return 'color: red'
            }
        },
        getCompPayment(compId) {
            apiAdmin.getCompClient(compId).then(result => {
                if(result.status === 'done') {
                    this.tariff = {
                        active: result.data.companyDataObj.license.activeAccount,
                        userBalance: result.data.companyDataObj.license.userBalance,
                        tariffCost: result.data.companyDataObj.license.userSumm
                    }

                    this.showTariff = true
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Не вийшло отримати дані по компанії'; 
                    var statusRequest = result.status;
                    var api = 'getCompClient';
                    var fileRequest = 'src/views/monitoring/view/index.vue';
                    var params = compId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        updateCard() {
            apiServe.getMonitoring(this.form.monitoringId).then(result => {
                if(result.status === 'done') {
                    this.form = result.data
                    this.componentKey++
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Не вийшло оновити картку моніторингу'; 
                    var statusRequest = result.status;
                    var api = 'getMonitoring';
                    var fileRequest = 'src/views/monitoring/view/index.vue';
                    var params = this.form.monitoringId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        getClient(num){
            this.eventBus.emit('getClient', {status: true, number: num})
        },
        resumeWork(){
            apiServe.resumeWork(this.form.monitoringId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit("getdata")
                    this.$emit("close")
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Не вийшло поновити роботу моніторингу'; 
                    var statusRequest = result.status;
                    var api = 'resumeWork';
                    var fileRequest = 'src/views/monitoring/view/index.vue';
                    var params = this.form.monitoringId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        inviteOne(e) {
            apiServe.assignWorkers(this.form.monitoringId, {workers: [e[0].workerId]}).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit("getdata")
                    this.$emit("close")
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Не вийшло назначити співробітників на моніторинг'; 
                    var statusRequest = result.status;
                    var api = 'assignWorkers';
                    var fileRequest = 'src/views/monitoring/view/index.vue';
                    var params = this.form.monitoringId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        checkMonitoring() {
            apiServe.checkMonitoring(this.form.monitoringId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit('getdata')
                    this.updateCard()
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Не вийшло перевірити моніторинг'; 
                    var statusRequest = result.status;
                    var api = 'checkMonitoring';
                    var fileRequest = 'src/views/monitoring/view/index.vue';
                    var params = this.form.monitoringId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        leftFromMonitoring() {
            apiServe.exitFromMonitoring(this.form.monitoringId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit("getdata")
                    this.$emit("close")
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Не вийшло покинути моніторинг'; 
                    var statusRequest = result.status;
                    var api = 'exitFromMonitoring';
                    var fileRequest = 'src/views/monitoring/view/index.vue';
                    var params = this.form.monitoringId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        stopMonitoring(id, obj) {
            apiServe.stopWork(id, obj).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit("getdata")
                    this.$emit("close")
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Не вийшло закрити моніторинг'; 
                    var statusRequest = result.status;
                    var api = 'stopWork';
                    var fileRequest = 'src/views/monitoring/view/index.vue';
                    var params = this.form.monitoringId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
    },
    computed: {
        user() {
            return storeS.userbase
        },
        perms() {
            return storeS.perms
        }
    }
}
</script>

<style lang="css" scoped>
.card-body {
    padding: 0.5rem !important;
}
</style>
